const getContextBoundURL = (url, user) => {
  if (url !== undefined && url !== "/login") {
    let parts = url.split('/')
    if (parts[1] === "organizations" && parts[2] !== undefined && parts.length === 3) {
      return {
        context: {
          containerId: parts[2],
          containerType: "Organization"
        }
      }
    } else if (parts[3] === "customers" && parts[4] !== undefined && parts.length === 5) {
      return {
        context: {
          containerId: parts[4],
          containerType: "Customer"
        }
      }
    } else if (parts[5] === "environments" && parts[6] !== undefined && parts.length === 7) {
      return {
        context: {
          containerId: parts[6],
          containerType: "Environment"
        }
      }
    } else {
      return {
        context: {
          containerId: "Site",
          containerType: "Site"
        }
      }
    }
  } else {
    return getContextBasedOnGroups(user)
  }
};

const getContextBasedOnGroups = (user) => {
  let contextId = "SITE"
  let contextType = "SITE"
  if (user.groups !== undefined && user.groups.length) {
    let organizations = []
    let customer = []
    let environments = []
    for (let key of user.groups) {
      if ("organization" in key) {
        organizations.push(key.organization)
      } else if ("customer" in key) {
        customer.push(key.customer)
      }
      else if ("environment" in key) {
        environments.push(key.environment)
      }
    }
    if (organizations.length > 1) {
      contextId = "SITE"
      contextType = "SITE"
    } else if (organizations.length === 1) {
      contextType = "ORGANIZATION"
      contextId = organizations[0].id
    } else if (customer.length > 1) {
      let organizations = []
      for (let key of customer) {
        if (key.hasOwnProperty('organization')) {
          organizations.push(key.organization.id)
        }
      }
      const allEqual = organizations => organizations.every(val => val === organizations[0]);
      if (allEqual) {
        contextType = "ORGANIZATION"
        contextId = organizations[0]
      } else {

      }
      contextId = "SITE"
      contextType = "SITE"
    } else if (customer.length === 1 && customer[0].hasOwnProperty('organization')) {
      contextType = "CUSTOMER"
      contextId = customer[0].shortName
    }
  }
  return {
    context: {
      containerId: contextId,
      containerType: contextType
    }
  }
}

export default {
  getContextBoundURL
};
