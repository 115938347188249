import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import  { AuthenticatedRoute } from './components/routes/AuthenticatedRoute';
import {UserContextProvider} from './components/contexts/UserContext';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>
)

const Layout = React.lazy(() => import('./components/layout/Layout'));
const Login = React.lazy(() => import('./components/login/Login'));
const Page404 = React.lazy(() => import('./components/error/Page404'));
const Page500 = React.lazy(() => import('./components/error/Page500'));
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <UserContextProvider>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
            <AuthenticatedRoute path="/" name="Home" component={Layout}/>
          </Switch>
        </React.Suspense>
        </UserContextProvider>
      </BrowserRouter>
    );
  }
}

export default App;
