import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import AuthenticationService from "../../services/AuthenticationService";

const getPreviousRoute = (props) => {
  if (props.location.pathname !== "/login") {
    if (props.location.hasOwnProperty('search') && props.location.search.includes("?source=")) {
      return props.location.pathname + props.location.search
    }
    return props.location.pathname
  }
}

export const AuthenticatedRoute = ({ component: Component, ...rest  }) => (
  <Route {...rest} render={(props) => (
    AuthenticationService.isLoggedIn()
      ? <Component {...props}/>
      : <Redirect to={{
        pathname: '/login',
        state: { from: getPreviousRoute(props) }
      }} />
  )} />
);
