import http from "../components/config/axiosConfig";

const getAll = (page, size) => {
  page = (typeof page !== 'undefined') ?  page : 0
  size = (typeof size !== 'undefined') ?  size : 10

  return http.get('/users', {
    params: {
      "page": page,
      "size": size
    }
  })
};

const search = (email, context) => {
  let headerData = !context ?
    {
      "container-id": 'Site',
      "container-type": 'Site'
    } :
    {
      "container-id": context.containerId,
      "container-type": context.containerType
    }
  return http.get('/users', {
    headers: headerData,
    params: {
      "search": "email~" + email
    }
  })
};

const getById = id => {
  return http.get(`/users/${id}`)
};

const getByUsername = name => {
  return http.get(`/users/username/${name}`, {
    headers: {
      "container-id": "Site",
      "container-type": "Site"
    },
  })
};

const create = (email, context) => {
  let headerData = !context ?
    {
      "Content-Type": 'application/json',
      "container-id": 'Site',
      "container-type": 'Site'
    } :
    {
      "Content-Type": 'application/json',
      "container-id": context.containerId,
      "container-type": context.containerType
    }
  return http.post('/users', email, {
    headers: headerData
  })
};

const update = (id, data) => {
  return http.put(`/users/${id}`, data)
};

const remove = id => {
  return http.delete(`/users/${id}`)
};

const addGroup = (id, data) => {
  let payload = {
    "groups": [data]
  }
  return http.put(`/users/${id}/groups`, payload)
};

const removeGroup = (id, data) => {
  let payload = {
    "groups": [data]
  }
  return http.delete(`/users/${id}/groups`, { data: payload })
};

const getUserPrivileges = (username, context) => {
  if (context !== undefined) {
    return http.get(`/users/${username}/privileges`, {
      headers: {
        "container-id": context.context.containerId,
        "container-type": context.context.containerType
      },
      params: {
        containerId: context.context.containerId,
        containerType: context.context.containerType
      }
    })
  }
};

const getByCustomerId = id => {
  return http.get(`/customers/${id}/users`, {
    headers: {
      "container-id": id,
      "container-type": "Customer"
    }
  })
};

const getByEnvironmentId = id => {
  return http.get(`/environments/${id}/users`, {
    headers: {
      "container-id": id,
      "container-type": "Environment"
    }
  })
};

const getByOrganizationId = id => {
  return http.get(`/organizations/${id}/users`, {
    headers: {
      "container-id": id,
      "container-type": "Organization"
    }
  })
};

export default {
  getAll,
  getById,
  create,
  update,
  remove,
  addGroup,
  removeGroup,
  getUserPrivileges,
  search,
  getByUsername,
  getByCustomerId,
  getByOrganizationId,
  getByEnvironmentId
};
