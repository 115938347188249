import decode from 'jwt-decode';
import axios from "axios";
import http from "../components/config/axiosContextConfig";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

httpClient.interceptors.request.use(function (config) {
  const token = getLocalToken();
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

const isLoggedIn = () => {
  const token = getLocalToken()
  return !!token && !isTokenExpired(token)
}

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp * 1000 < Date.now()) {
      removeToken()
      return true
    }
  }
  catch (err) {
    return true;
  }
}

const setLocalToken = (idToken) => {
  localStorage.setItem('id_token', idToken)
}

const getLocalToken = () => {
  return localStorage.getItem('id_token')
}

const logout = () => {
  localStorage.removeItem('id_token');
}

const removeToken = () => {
  localStorage.removeItem('id_token');
}

const getToken = () => {
  if (!isLoggedIn()) {
    return null;
  }
  return decode(getLocalToken());
}

const getUserName = () => {
  let username = null;
  let token = getToken();

  if (!isLoggedIn()) {
    return null;
  }

  if (token && token.hasOwnProperty("sub")) {
    username = token.sub;
  }
  return username;
}

const authenticate = (credentials) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  return http.post('/login', JSON.stringify(credentials), {
    headers: headers
  })
}

export default {
  isLoggedIn,
  isTokenExpired,
  setLocalToken,
  getLocalToken,
  logout,
  removeToken,
  getToken,
  getUserName,
  authenticate
};
