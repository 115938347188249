import axios from 'axios';
import AuthenticationService from "../../services/AuthenticationService";
const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
});

axiosConfig.interceptors.request.use(function (config) {
  const token = AuthenticationService.getLocalToken();
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

export default axiosConfig;
