import React, {createContext, useState, useEffect, useMemo, useCallback} from "react";
import UserService from "../../services/UserService";
import {useHistory, useLocation} from "react-router-dom";
import ContextHelper from "../util/ContextHelper";
import AuthenticationService from "../../services/AuthenticationService";

const UserContext = createContext(undefined);

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    authenticated: false,
    username: "",
    privileges: [],
    groups: [],
    context: null,
    email: "",
    loginPath: null
  });
  const [error, setError] = useState();
  const [loadingInitial, setLoadingInitial] = useState(false)
  const [loadingPrivileges, setLoadingPrivileges] = useState(false)
  const [expiredSession, setExpiredSession] = useState(false)
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const p = location.pathname

  const getUserPrivileges = useCallback((user, route) => {
    setLoadingPrivileges(true)
    const context = ContextHelper.getContextBoundURL(route ? route : window.location.pathname, user)
    UserService.getUserPrivileges(user.username, context)
      .then(response => {
        setUser((prevState) => {
          return({
            ...prevState,
            privileges: response.data
          });
        });
      })
      .catch(e => {
        handleError(e)
      })
      .finally(() => {
        setLoadingPrivileges(false)
      })
  }, []);

  const fetchUser = useCallback(() => {
    setLoadingInitial(true)
    if (AuthenticationService.isLoggedIn()) {
      if (AuthenticationService.getUserName() !== null) {
        UserService.getByUsername(AuthenticationService.getUserName())
          .then(response => {
            setUser((prevState) => {
              return({
                ...prevState,
                authenticated: true,
                username: response.data.username,
                groups: response.data.groups,
                context: response.data.context,
                email: response.data.email,
                loginPath: response.data.loginPath
              });
            });
            getUserPrivileges(response.data, p)
          })
          .catch(e => {
            handleError(e)
          })
          .finally(() => {
            setLoadingInitial(false)
          })
      }
    }
  }, [p, getUserPrivileges]);

  const login = useCallback((credentials) => {
    setLoading(true)
    AuthenticationService.authenticate(credentials)
      .then(response => {
        if (response.status === 200) {
          if (response.headers['x-amzn-remapped-authorization'] !== undefined && response.headers['x-amzn-remapped-authorization'].length) {
            AuthenticationService.setLocalToken(response.headers['x-amzn-remapped-authorization'].split(" ")[1]);
          } else {
            AuthenticationService.setLocalToken(response.headers.authorization.split(" ")[1]);
          }
          if (AuthenticationService.isLoggedIn()) {
            if (AuthenticationService.getUserName() !== null) {
              fetchUser(AuthenticationService.getUserName())
            }
          }
        }
        else {
          throw new Error("An error has occurred " + response.status + " " + response.statusText);
        }
      })
      .catch((e) => {
        let message = "A user could not be found with the provided credentials"
        if (e.hasOwnProperty('response')) {
          if (e.response.hasOwnProperty('data')) {
            if (e.response.data.hasOwnProperty('error')) {
              message = e.response.data.error
            }
          }
        }
        handleError({
          message: message
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [fetchUser]);

  const logoutUser = useCallback(() => {
    AuthenticationService.logout()
    setUser(() => {
      return({
        authenticated: false,
        username: null,
        groups: [],
        context: null,
        email: null,
        loginPath: null
      });
    });
    setExpiredSession(false)
    history.push("/login");
  }, [history]);

  const handleError = (error) => {
    if (error.hasOwnProperty('message')) {
      setError(error.message)
    } else if (error.hasOwnProperty('error')) {
      setError(error.message)
    }
    console.log(error)
  }

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (location.pathname !== "/login") {
      const token = AuthenticationService.getToken()
      if (token) {
        if (AuthenticationService.isTokenExpired()) {
          setExpiredSession(true)
          setUser(() => {
            return({
              authenticated: false,
              username: null,
              groups: [],
              context: null,
              email: null,
              loginPath: null
            });
          });
        }
      } else {
        setUser(() => {
          return({
            authenticated: false,
            username: null,
            groups: [],
            context: null,
            email: null,
            loginPath: null
          });
        });
      }
    }
  }, [location.pathname]);

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logoutUser,
      expiredSession
    }),
    [user, loading, error, logoutUser, login, expiredSession]
  );

  return (
    <UserContext.Provider value={memoedValue}>
      {location.pathname !== "/login" ?
        (!loadingInitial && !loadingPrivileges) && children
      : children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
